<template>
  <FilterButton :disabled="disabled" @click="showBusinessUnitSelectModal = true">
    Business Unit
    <template v-if="businessUnits.length">
      <template v-if="businessUnits.length < 4">
        <Badge v-for="businessUnit in businessUnits" :key="businessUnit.id" size="sm">
          {{ businessUnit.name }}
        </Badge>
      </template>
      <Badge v-else>{{ businessUnits.length }} sélectionnés</Badge>

      <FilterClear v-if="!disabled" @click.stop="businessUnits = []" />
    </template>
  </FilterButton>

  <LazyBusinessUnitSelectModal
    v-if="showBusinessUnitSelectModal"
    v-bind="forwarded"
    v-model="businessUnits"
    is-multiple
    is-selected-option-removable
    @on-close="showBusinessUnitSelectModal = false"
  />
</template>

<script setup lang="ts">
import type { Props, Emits } from "~/components/business-unit/BusinessUnitSelectModal.vue";
import { useForwardPropsEmits } from "radix-vue";

const props = defineProps<Props & { disabled?: boolean }>();

const emit = defineEmits<Emits>();

const { modelValue: businessUnits } = useVModels(props, emit);
const showBusinessUnitSelectModal = defineModel<boolean>("showBusinessUnitSelectModal");

const forwarded = useForwardPropsEmits(props, emit);
</script>

<style scoped></style>
